import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ods-search-result-item',
  standalone: true,
  imports: [CommonModule],
  template: `<button
    *ngIf="title"
    [ngClass]="[
      'flex w-full justify-between border-2 border-transparent px-6 py-3',
      'hover:border-focus focus:border-focus focus:outline-none',
    ]"
    role="listitem"
    tabindex="-1"
    (click)="itemClicked.emit()"
    data-test-id="item-button"
    #button
  >
    <div class="flex flex-col items-start justify-between text-text">
      <p class="text-base font-medium">{{ title }}</p>
      <p class="text-sm">{{ description }}</p>
    </div>
    <ng-content select="[action-button]" />
  </button>`,
})
export class SearchResultItemComponent {
  @Input({ required: true }) title!: string;
  @Input() description: string = '';

  @Output() public itemClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild('button') buttonRef: ElementRef;

  public setFocus() {
    this.buttonRef.nativeElement.focus();
  }
}
