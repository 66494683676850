import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ods-search-result-layer',
  standalone: true,
  imports: [CommonModule],
  template: `<div class="rounded-lg border border-primary-600/50 bg-background-50 shadow-lg">
    <ng-content select="[header]" />
    <ul role="list">
      <ng-content />
    </ul>
  </div>`,
})
export class SearchResultLayerComponent {}
