import { EMPTY_STRING, TechSharedModule, convertForDataTest } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { VariantProps, cva } from 'class-variance-authority';

const textareaVariants = cva(
  [
    'w-full box-border rounded-lg border bg-background-50 px-3 py-2 text-base text-text leading-5',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
  ],
  {
    variants: {
      variant: {
        default:
          'border-primary-600/50 focus-visible:outline-focus focus-visible:border-background-200 hover:border-primary-hover',
        error:
          'border-error/50 hover:border-error focus-visible:outline-error focus-visible:border-background-200',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
type TextareaVariants = VariantProps<typeof textareaVariants>;

@Component({
  selector: 'ods-textarea',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TechSharedModule],
  template: `
    <div class="mt-2">
      <label [for]="id" class="text-md mb-2 block font-medium text-text">
        {{ inputLabel }}<ng-container *ngIf="required"><i aria-hidden="true">*</i></ng-container>
      </label>
      <textarea
        [id]="id"
        [formControl]="fieldControl"
        [rows]="rows"
        [ngClass]="textareaVariants({ variant })"
        [placeholder]="placeholder"
        [autocomplete]="autocomplete"
        [attr.aria-required]="required"
        [attr.aria-invalid]="variant === 'error'"
        [attr.data-test-id]="(inputLabel | convertForDataTest) + '-textarea'"
        #textAreaElement
      ></textarea>
      <ng-content select="[error]"></ng-content>
    </div>
  `,
})
export class TextareaComponent {
  @ViewChild('textAreaElement') textAreaElement: ElementRef;

  @Input({ required: true }) set label(label: string) {
    this.inputLabel = label;
    this.id = convertForDataTest(label);
  }
  @Input({ required: true }) placeholder!: string;
  @Input() error: string;
  @Input() rows: number = 3;
  @Input() autocomplete: string = 'off';
  @Input() variant: TextareaVariants['variant'];
  @Input() fieldControl: FormControl = new FormControl(EMPTY_STRING);
  @Input() required: boolean = false;

  @Input() set focus(value: boolean) {
    if (value && this.textAreaElement) {
      this.textAreaElement.nativeElement.focus();
    }
  }

  inputLabel: string;
  id: string;
  textareaVariants = textareaVariants;
}
